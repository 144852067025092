<template>
  <div style="min-height: 750px;" v-if="d_usrShpBundProductAgreementWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shop_bundle_product_agreement_list'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin-bottom: 10px;">
          <b-col sm="12" lg="6"> </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button size="sm" @click="f_newUserShopBundleProductAgreement()" variant="primary">
              {{ StoreLangTranslation.data['new_user_shop_bundle_product_agreement'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-input id="search" v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="2">
            <strong> Id </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpBundProductAgreementWdm['0']['parameters']['0']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="7">
            <strong> {{ d_usrShpBundProductAgreementWdm['0']['parameters']['1']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1"> </b-col>
        </b-row>
        <b-row v-for="(wdmr_item, wdmr_item_ind) in d_usrShpBundleProductAgreementList" v-if="f_searchList(wdmr_item)" @mouseover="d_hoverIndex = wdmr_item_ind" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px; border-bottom: solid 1px #dcdcdc;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc;'">
          <b-col sm="12" lg="2">
            <small style="color: blue;"> {{ wdmr_item.client_id }}</small>
          </b-col>
          <b-col sm="12" lg="2">
            
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['0'] && wdmr_item.data['0']['0']['val']['translation']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['0']['val']['translation'][StoreLang] }}</span>
            </template>
          
          </b-col>
          <b-col sm="12" lg="7">
            
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['1'] && wdmr_item.data['0']['1']['lang'] && wdmr_item.data['0']['1']['lang'][StoreLang]">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['1']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['1'] && wdmr_item.data['0']['1']['val']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['1']['val'] }}</span>
            </template>
          
          </b-col>
          <b-col sm="12" lg="1">
            <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
              <template slot="button-content">
                işlem
              </template>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_editThisUserShopBundleProductAgreement(wdmr_item.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['edit'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_agrementView(wdmr_item.client_id)"><i class="fa fa-sign-in"></i> {{ StoreLangTranslation.data['agreement_preview'][StoreLang] }} </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_agreementModal.show" id="d_agreementModal_show" v-model="d_agreementModal.show" scrollable hide-footer :title="StoreLangTranslation.data['agreement_preview'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <agreement-view :p_shopData="d_agreementModal.shop_data" :p_bundleProductData="d_agreementModal.bundle_product_data"></agreement-view>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as AgreementView
} from '@/wam/components/widgets/AgreementView';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_bundle_product_agreement_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    AgreementView,
  },
  props: {},
  data () {
    return {
      d_agreementModal: {
        'show': false,
        'shop_data': {},
        'bundle_data': {},
        'bundle_product_data': {},
      },
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_userShopClientID: '',
      d_userShopBundleClientID: '',
      d_userShopBundleProductClientID: '',
      d_usrShpBundleProductAgreementList: [],
      d_usrShpBundProductAgreementWdm: '',
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shp_bund_pro_agr').then(resp_wdm_data => { this.d_usrShpBundProductAgreementWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.d_userShopClientID = this.$route.query.user_shop_client_id;
    this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
    this.d_userShopBundleProductClientID = this.$route.query.user_shop_bundle_product_client_id;
    if (this.d_user && this.d_user.username && this.d_userShopClientID && this.d_userShopBundleClientID && this.d_userShopBundleProductClientID) {
      this.f_userShopBundleProductAgreementList();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpBundProductAgreementWdm);
  },
  methods: {
    f_agrementView: function (user_shop_bundle_product_agreement_client_id) {
      this.d_agreementModal.shop_data = {'client_id': this.d_userShopClientID };
      this.d_agreementModal.bundle_product_data = {'client_id': this.d_userShopBundleProductClientID, 'agreement_client_id': user_shop_bundle_product_agreement_client_id };
      this.d_agreementModal.show = true;
    },
    f_newUserShopBundleProductAgreement: function () {
      let route_go_data = {
        'path': ClsRoute.f_getRoutePath('user_shop_bundle_product_agreement_record', this.StoreLang),
        'query': {
          'user_shop_client_id': this.d_userShopClientID ,
          'user_shop_bundle_client_id': this.d_userShopBundleClientID,
          'user_shop_bundle_product_client_id': this.d_userShopBundleProductClientID,
        }
      };
      this.$router.push(route_go_data);
    },
    f_editThisUserShopBundleProductAgreement: function (user_shop_bundle_product_agreement_client_id) {
      let route_path = ClsRoute.f_getRoutePath('user_shop_bundle_product_agreement_record', this.StoreLang);
      // console.log('route_path : ', route_path);
      let route_go_data = {
        'path': route_path,
        'query': {
          'user_shop_client_id': this.d_userShopClientID ,
          'user_shop_bundle_client_id': this.d_userShopBundleClientID,
          'user_shop_bundle_product_client_id': this.d_userShopBundleProductClientID,
          'user_shop_bundle_product_agreement_client_id': user_shop_bundle_product_agreement_client_id
        }
      };
      this.$router.push(route_go_data);
    },
    f_searchList: function (wdmr_item) {
      let res = false;
      if (this.d_searchText) {
        if (wdmr_item.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_userShopBundleProductAgreementList: function () {
      let query = 'user_shop_bundle_product_client_id=' + this.d_userShopBundleProductClientID;
      let data = {};
      ServiceFunctions.user_shop_bundle_product_agreement_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpBundleProductAgreementList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

